import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST VIDEO
export const getVideo = createAsyncThunk('video/getVideo', async () => {
  const params = {
    by: 'def.title',
    start_date: localStorage.getItem('start_date'),
    end_date: localStorage.getItem('end_date'),
    active: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/VideoPromote/select?`, config)

  return response.data.data.list_VideoPromote
})

//LIST VIDEO FILTER
export const getVideoFilter = createAsyncThunk('video/getVideoFilter', async (parameter) => {
  const params = {
    by: 'def.title',
    start_date: localStorage.getItem('start_date'),
    end_date: localStorage.getItem('end_date'),
    active: parameter.active,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/VideoPromote/select?`, config)

  return response.data.data.list_VideoPromote
})

//ADD VIDEO
export const addVideo = createAsyncThunk('video/addVideo', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/apps/VideoPromote/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_VideoPromote
})

//UPDATE VIDEO
export const updateVideo = createAsyncThunk('video/addVideo', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/apps/VideoPromote/id/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_VideoPromote
})

//DELETE VIDEO
export const deleteVideo = createAsyncThunk('video/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/apps/VideoPromote/id/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const videoEntity = createEntityAdapter({
  selectId: (video) => video.id,
})

const videoSlice = createSlice({
  name: 'video',
  initialState: videoEntity.getInitialState(),
  extraReducers: {
    [getVideo.fulfilled]: (state, action) => {
      videoEntity.setAll(state, action.payload)
    },
    [getVideoFilter.fulfilled]: (state, action) => {
      videoEntity.setAll(state, action.payload)
    },

    [addVideo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        videoEntity.addOne(state, action.payload)
      }
    },

    [deleteVideo.fulfilled]: (state, action) => {
      videoEntity.removeOne(state, action.payload)
    },
    [updateVideo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        videoEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
  },
})

export const videoSelector = videoEntity.getSelectors((state) => state.video)
export default videoSlice.reducer
