import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST FOLDER
export const getFolder = createAsyncThunk('folder/getFolder', async (parameter) => {
  const params = {
    by: 'def._name',
    sort: 'desc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/upload-folder/select?`, config)
  console.log('response data:', response)
  return response.data.data
})

//LIST FOLDER FILTER SELECT ALL
export const getFolderFilterSelectAll = createAsyncThunk('folder/getFolder', async (parameter) => {
  const params = {
    by: 'def._name',
    sort: 'desc',
    name: parameter.name,
    branch_id: parameter.branch_id,
    customer_code: parameter.customer_code,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/upload-folder/select?`, config)
  console.log('response data:', response)
  return response.data.data
})

//LIST FOLDER PAGINATION FILTER
export const getFolderFilter = createAsyncThunk('folder/getFolderFilter', async (parameter) => {
  const params = {
    by: 'def._name',
    sort: 'desc',
    name: parameter.name,
    branch_id: parameter.branch_id,
    customer_code: parameter.customer_code,
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/upload-folder?`, config)
  const responseData = {
    data: response.data.data == null ? [] : response.data.data,
    meta: response.data.meta,
  }
  //console.log('search toko:', response)
  return responseData
})

//ADD FOLDER
export const addFolder = createAsyncThunk('folder/addFolder', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/upload-folder/`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE FOLDER
export const updateFolder = createAsyncThunk('folder/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/upload-folder/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data
})

//DELETE FOLDER
export const deleteFolder = createAsyncThunk('folder/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/upload-folder/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const folderEntity = createEntityAdapter({
  selectId: (folder) => folder.id,
})

const folderSlice = createSlice({
  name: 'folder',
  initialState: folderEntity.getInitialState(),
  extraReducers: {
    [getFolder.fulfilled]: (state, action) => {
      folderEntity.setAll(state, action.payload)
    },

    [getFolderFilterSelectAll.fulfilled]: (state, action) => {
      folderEntity.setAll(state, action.payload)
    },

    [getFolderFilter.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      folderEntity.setAll(state, action.payload)
    },

    [addFolder.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        folderEntity.addOne(state, action.payload)
      }
    },

    [deleteFolder.fulfilled]: (state, action) => {
      folderEntity.removeOne(state, action.payload)
    },
    [updateFolder.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        folderEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
  },
})

export const folderSelector = folderEntity.getSelectors((state) => state.folder)
export default folderSlice.reducer
