import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST INFO
export const getInfo = createAsyncThunk('info/getInfo', async () => {
  const params = {
    // by: 'def.title',
    by: 'def.priority',
    sort: 'asc',
    start_date: localStorage.getItem('start_date'),
    end_date: localStorage.getItem('end_date'),
    active: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/news/select?`, config)

  return response.data.data.list_news
})

//LIST INFO
export const getInfoFilter = createAsyncThunk('info/getInfo', async (parameter) => {
  const params = {
    // by: 'def.title',
    by: 'def.priority',
    sort: 'asc',
    start_date: localStorage.getItem('start_date'),
    end_date: localStorage.getItem('end_date'),
    active: parameter.active,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/news/select?`, config)

  return response.data.data.list_news
})

//ADD INFO
export const addInfo = createAsyncThunk('info/addInfo', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/apps/news/add`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_news
})

//ADD PHOTO
export const addImageInfo = createAsyncThunk('info/addImage', async ({ formdata }) => {
  const response = await axios
    .post(`${URL_API}/v1/api/apps/news/photo`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE INFO
export const updateInfo = createAsyncThunk('info/update', async ({ formdata }) => {
  const response = await axios
    .put(`${URL_API}/v1/api/apps/news/id/` + formdata.id, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  return response.data.data.list_news
})

//DELETE NEWS
export const deleteInfo = createAsyncThunk('info/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/apps/news/id/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const infoEntity = createEntityAdapter({
  selectId: (info) => info.id,
})

const infoSlice = createSlice({
  name: 'info',
  initialState: infoEntity.getInitialState(),
  extraReducers: {
    [getInfo.fulfilled]: (state, action) => {
      infoEntity.setAll(state, action.payload)
    },
    [getInfoFilter.fulfilled]: (state, action) => {
      infoEntity.setAll(state, action.payload)
    },

    [addInfo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        infoEntity.addOne(state, action.payload)
      }
    },
    [addImageInfo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        infoEntity.addOne(state, action.payload)
      }
    },
    [deleteInfo.fulfilled]: (state, action) => {
      infoEntity.removeOne(state, action.payload)
    },
    [updateInfo.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        infoEntity.updateOne(state, {
          id: action.payload.id,
          updates: action.payload,
        })
      }
    },
  },
})

export const infoSelector = infoEntity.getSelectors((state) => state.info)
export default infoSlice.reducer
