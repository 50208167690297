import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// DETAIL FOLDER
export const getFolderDetail = createAsyncThunk('folder/detail', async (parameter) => {
  const params = {
    upload_folder_id: parameter.upload_folder_id,
    by: 'created_at',
    sort: 'asc',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios
    .get(`${URL_API}/v1/api/upload/select/group?`, config)

    .catch((err) => {
      // console.log(err.response)
    })

  return response.data.data == null ? [] : response.data.data
})

const folderDetailEntity = createEntityAdapter({
  selectId: (folderDetail) => folderDetail.customer_code,
})

const folderDetailSlice = createSlice({
  name: 'folderDetail',
  initialState: folderDetailEntity.getInitialState(),
  extraReducers: {
    [getFolderDetail.fulfilled]: (state, action) => {
      folderDetailEntity.setAll(state, action.payload)
    },
  },
})

export const folderDetailSelector = folderDetailEntity.getSelectors((state) => state.folderDetail)
export default folderDetailSlice.reducer
